<template>
  <div class="header">
    <button
      @click="back"
      class="btn-img">
      <img src="../../assets/icon-back.png">
    </button>
  </div>
  <select-lesson-test
    @submit="startExam"/>
</template>

<script>
import SelectLessonTest from '../../components/molecule/SelectLessonTest.vue'

export default {
  name: 'Lessons',
  components: { SelectLessonTest, },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    startExam(lesson) {
      this.$store.commit('selectTest', lesson)
      this.$router.push(`/lesson/${lesson.id}/exam`)
    },
    back() {
      this.$router.back()
    },
  }
}
</script>